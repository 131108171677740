














import { Component, Vue } from 'vue-property-decorator';
import '../../rem';

@Component({
  components: {
    InsHeader: () =>
      import('@/components/business/mobile/header/InsHeader.vue'),
    InsFooter: () =>
      import('@/components/business/mobile/footer/InsFooter.vue'),
    InsSlideMenu: () =>
      import('@/components/business/mobile/header/InsSlideMenu.vue'),
    InsMenuLayout: () =>
      import('@/components/business/mobile/header/InsMenuLayout.vue'),
    InsSidebar: () => import('@/components/business/mobile/header/InsSidebar.vue')
  }
})
export default class mobileIndex extends Vue {
  created () {
    Vue.prototype.vw = window.innerWidth;
  }
  mounted () {}
}
